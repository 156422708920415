import React, { useEffect, useState } from "react";

import {
  Row,
  Typography,
  Col,
  Spin,
  Table,
  Avatar,
  Card,
  Form,
  Tag,
  InputNumber,
} from "antd";

import { FONT } from "constants/index";

import { ColumnsChart } from "./columns-table";
import { ArrowUpFromLine, UserRound } from "lucide-react";
import { chartBar } from "../reviews/reviews-drawer-charts";
import DrawerApp from "components/drawer-app";
import { updateTraining } from "services/planning/planning-service";
import { notificationSucess } from "util/api-utils";

const { Text } = Typography;
const { Meta } = Card;

const ManagerialChart = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [planTraining, setPlanTraning] = useState(null);
  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);
  const [formUser] = Form.useForm();

  useEffect(() => {
    setLoadingData(true);

    const planTraining = props.user?.planningTrainings?.filter(
      (pt) => pt.planningType == "BACK_SQUAD"
    );

    let dataPlanning = planTraining[0]?.allTrainings ?? [];
    dataPlanning = dataPlanning.map((obj) => ({
      ...obj,
      edit: edit,
    }));
    setData(dataPlanning);
    setPlanTraning(planTraining[0]);
    setLoadingData(false);
  }, [props]);

  function edit(row) {
    setEditId(row);
    showDrawer();
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setEditId(null);
    setOpen(false);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const diferenca = payload[1]?.value - payload[0]?.value;
      const percentual = (diferenca / payload[1]?.value) * 100;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "6px",
            fontFamily: FONT,
          }}
        >
          <p className="intro">{`${label}`}</p>
          <p className="intro">
            VMP Esperada:{" "}
            <span style={{ color: "#14B8A6" }}>{payload[0]?.value}</span>
          </p>
          {payload[1]?.value && (
            <p className="intro">
              VMP Atual:{" "}
              <span style={{ color: "#3B82F6" }}>{payload[1]?.value}</span>
            </p>
          )}

          {payload[1]?.value && (
            <p className="intro">
              Comparativo:{" "}
              <span style={{ color: percentual > 0 ? "#14B8A6" : "#EC6D62" }}>
                {percentual.toFixed(2)}%
              </span>
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  function getWeek(trainings) {
    const week = trainings?.load;
    const type = trainings?.orderTraining;

    if (week) {
      const typeTraining = type % 2 === 0 ? "B" : "A";
      const color = type % 2 === 0 ? "geekblue" : "green";

      return (
        <div>
          Semana {week} -{" "}
          <Tag color={color} key={typeTraining}>
            {typeTraining.toUpperCase()}
          </Tag>
        </div>
      );
    }
    return "Cíclo Finalizado";
  }

  const DescriptionItem = ({ title }) => (
    <div
      className="site-description-item-profile-wrapper"
      style={{ fontFamily: FONT, fontSize: 16 }}
    >
      <b>{title}</b>
    </div>
  );

  const CardTraining = ({ row }) => {
    formUser.setFieldValue("vmp1", row?.vmp1);
    formUser.setFieldValue("vmp2", row?.vmp2);
    formUser.setFieldValue("vmp3", row?.vmp3);
    formUser.setFieldValue("vmp4", row?.vmp4);

    return (
      <Col key={row?.id} span={24}>
        <Card
          style={{
            width: "100%",
            marginBottom: 16,
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Meta
            title={<div>{getWeek(row)}</div>}
            description={
              <div>
                <Row>
                  <Col span={24}>
                    <DescriptionItem title={"Exercício Agachamento"} />
                  </Col>
                </Row>

                <Row style={{ marginTop: 40 }}>
                  <Col span={24}>
                    <DescriptionItem title={"VMP Agachamento"} />
                  </Col>
                </Row>

                <Form form={formUser} layout="vertical">
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item name="vmp1">
                        <InputNumber
                          decimalSeparator=","
                          style={{ fontFamily: FONT, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="vmp2">
                        <InputNumber
                          decimalSeparator=","
                          style={{ fontFamily: FONT, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="vmp3">
                        <InputNumber
                          decimalSeparator=","
                          style={{ fontFamily: FONT, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name="vmp4">
                        <InputNumber
                          decimalSeparator=","
                          style={{ fontFamily: FONT, width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            }
          />
        </Card>
      </Col>
    );
  };
  function mountChart() {
    return data.map((item) => {
      const vmps = [item.vmp1, item.vmp2, item.vmp3, item.vmp4].filter(
        (item) => item != null
      );
      var vmpMax = null;
      if (vmps.length > 0) {
        vmpMax = Math.max(...vmps);
      }
      const vmpEsperada = planTraining[`vmp${item.load}`];
      return {
        load: "Semana - " + item.load,
        vmpEsperada: vmpEsperada,
        vmpAtual: vmpMax,
      };
    });
  }
  const handeSave = () => {
    setLoadingData(true);
    const newValues = formUser.getFieldValue();

    const newData = data.map((item) => {
      if (item.id == editId.id) {
        return { ...item, ...newValues };
      } else {
        return item;
      }
    });
    const obj = {
      id: editId.id,
      vmp1: newValues.vmp1,
      vmp2: newValues.vmp2,
      vmp3: newValues.vmp3,
      vmp4: newValues.vmp4,
    };
    updateTraining(obj)
      .then(() => {
        notificationSucess();
        setData(newData);
        onClose();
        formUser.resetFields();
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  };
  return (
    <>
      <Spin spinning={loadingData}>
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Avatar
              src={props.user?.img}
              icon={<UserRound size={120} />}
              size={120}
            />
          </Col>
        </Row>
        <Table
          columns={ColumnsChart()}
          dataSource={data}
          size={"small"}
          pagination={{ pageSize: 5, showSizeChanger: false }}
          locale={{
            emptyText: "Sem registro",
          }}
        />
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <ArrowUpFromLine
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                      Evolução VMP
                    </Text>
                  </div>
                </div>
              }
              style={{ width: "100%" }}
            >
              {chartBar(
                mountChart(),
                "load",
                "vmpEsperada",
                "vmpAtual",
                "VMP Esperada",
                "VMP Atual",
                10,
                <CustomTooltip />
              )}
            </Card>
          </Col>
        </Row>
      </Spin>
      <DrawerApp
        open={open}
        onClose={onClose}
        title={"Editar Treino"}
        body={<CardTraining row={editId} />}
        actionSave={handeSave}
        width={outerWidth < 501 ? "100%" : "30%"}
      />
    </>
  );
};
export default ManagerialChart;

import { Avatar, Space, Tooltip } from "antd";
import { FONT } from "constants/index";
import {
  ChartColumnStacked,
  ClipboardPen,
  Eye,
  SquareChartGantt,
  Trash2,
  UserRound,
} from "lucide-react";

export const translationsTypes = {
  BACK_SQUAD: "Agachamento",
  BARBELL_JUMP: "Salto com Barra",
  HANG_CLEAN: "Hang Clean",
  NEW_EXERCISE: "Exercício Específico",
  ADDITIONAL_EXERCISE: "Exercício Complementar",
};
export const ColumnsUser = () => {
  const table = [
    {
      title: "Foto",
      dataIndex: "image",
      width: "15%",
      render: (text, row) => {
        return (
          <Avatar src={row.img} icon={<UserRound size={50} />} size={50} />
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      textWrap: "word-break",
      ellipsis: true,
      width: "15%",
    },
  ];

  const tableDefault = [
    {
      title: "E-mail",
      dataIndex: "email",
      textWrap: "word-break",
      width: "25%",

      ellipsis: true,
    },
    {
      title: "Última Planificação",
      dataIndex: "lastPlanning",
      textWrap: "word-break",
      width: "15%",
      ellipsis: true,
    },
  ];
  if (outerWidth > 501) {
    table.push(...tableDefault);
  }

  table.push({
    title: "Ações",
    dataIndex: "action",
    width: "25%",
    render: (text, row) => {
      return (
        <Space size="middle">
          <Tooltip style={{ fontFamily: FONT }} title="Nova Planificação">
            <SquareChartGantt
              onClick={() => row.edit(row)}
              size={22}
              style={{ cursor: "pointer", color: "#09ac9c" }}
            />
          </Tooltip>
          <Tooltip style={{ fontFamily: FONT }} title="Histórico">
            <ChartColumnStacked
              onClick={() => row.detail(row)}
              size={22}
              style={{ cursor: "pointer", color: "#2980B9" }}
            />
          </Tooltip>
        </Space>
      );
    },
  });
  return table;
};

export const ColumnsLastReview = (type) => {

  const rm = type == "BARBELL_JUMP" ? "ALT(CM)" : "%1RM";

  const table = [
    {
      title: "Carga Absoluta",
      dataIndex: "absoluteStrength",
      textWrap: "word-break",
      ellipsis: true,
      width: "30%",
    },
    {
      title: rm,
      dataIndex: "rm",
      textWrap: "word-break",
      ellipsis: true,
      width: "30%",
    },
  ];

  if (type != "HANG_CLEAN" && type != "BARBELL_JUMP") {
    table.push({
      title: "VMP",
      dataIndex: "vmp",
      textWrap: "word-break",
      ellipsis: true,
      width: "30%",
    });
  }

  return table;
};
export const ColumnsExercises = () => {
  const table = [
    {
      title: "Nome",
      dataIndex: "type",
      textWrap: "word-break",
      ellipsis: true,
      width: "30%",
      render: (text, row) => {
        if (text == "ADDITIONAL_EXERCISE") {
          return (
            <div>
              {translationsTypes[text]} ( {row.additionalName} )
            </div>
          );
        } else {
          return translationsTypes[text] || text;
        }
      },
    },
    {
      title: "Ciclos",
      dataIndex: "cycle",
      textWrap: "word-break",
      ellipsis: true,
      width: "30%",
    },
    {
      title: "Ações",
      dataIndex: "action",
      width: "25%",
      render: (text, row) => {
        return (
          <Space size="middle">
            {row.edit && (
              <Tooltip style={{ fontFamily: FONT }} title="Editar Exercício">
                <ClipboardPen
                  onClick={() => row.edit(row)}
                  size={22}
                  style={{ cursor: "pointer", color: "#2980B9" }}
                />
              </Tooltip>
            )}
            {row.remove && (
              <Tooltip style={{ fontFamily: FONT }} title="Deletar Exercício">
                <Trash2
                  onClick={() => row.remove(row)}
                  size={22}
                  style={{ cursor: "pointer", color: "#EC6D62" }}
                />
              </Tooltip>
            )}
            {row.detail && (
              <Tooltip
                style={{ fontFamily: FONT }}
                title="Detalhe do Exercício"
              >
                <Eye
                  onClick={() => row.detail(row)}
                  size={22}
                  style={{ cursor: "pointer", color: "#2980B9" }}
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];
  return table;
};

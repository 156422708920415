import React, { useEffect, useState } from "react";
import {
  Spin,
  Button,
  Row,
  Input,
  Space,
  Card,
  Table,
  Typography,
  Modal,
} from "antd";
import { ArrowRight, Medal, Search } from "lucide-react";
import { FONT } from "constants/index";
import { notificationErroInternal } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import { getUsersPlanning } from "services/planning/planning-service";
import DrawerApp from "components/drawer-app";
import FormNewUserPlanning from "./planning-drawer-new-planning";
import FormUserPlanningDetails from "./planning-drawer-details";


const { Text } = Typography;

const Planning = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLoading(true);
    getData();
  }

  function getData() {
    let obj = {
      search: search,
    };

    if (search == null || search == "") {
      obj = {};
    }
    getUsersPlanning(obj)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
          edit: edit,
          detail: detail,
        }));
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const mountFilter = () => {
    return (
      <Row gutter={24} style={{ padding: "10px" }}>
        <Space>
          <Input
            placeholder="Pesquisar Aluno"
            onChange={(value) => setSearch(value.target.value)}
            suffix={<Search size={18} color="#e0e0e0" />}
            style={{ height: "40px" }}
            onPressEnter={refreshData}
          />

          <Button
            style={{ fontFamily: FONT }}
            type="primary"
            icon={
              <ArrowRight
                size={18}
                style={{ marginBottom: "-3px", marginRight: "7px" }}
              />
            }
            onClick={refreshData}
          >
            Filtrar
          </Button>
        </Space>
      </Row>
    );
  };
  const onClose = () => {
    setEditId(null);
    setOpen(false);
    refreshData();
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDetail = () => {
    setEditId(null);
    setOpenDetail(false);
    refreshData();
  };
  const showDrawerDetail = () => {
    setOpenDetail(true);
  };
  function edit(row) {
    setEditId(row.id);
    showDrawer();
  }
  function detail(row) {
    setEditId(row.id);
    showDrawerDetail();
  }
  return (
    <div className="container-planning">
      <Spin size="large" spinning={loading}>
        {mountFilter()}
        <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
          <Space
            direction="vertical"
            size={"small"}
            style={{
              flex: "1 1 ",
              minWidth: outerWidth < 501 ? "0px" : "500px",
            }}
          >
            <Card
              id="card-table"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Medal
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                      Planificação dos Alunos
                    </Text>
                  </div>
                </div>
              }
              bordered={false}
            >
              <Table
                rowKey={"id"}
                columns={ColumnsUser()}
                dataSource={data}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                locale={{
                  emptyText: "Sem registro",
                }}
                scroll={{ x: 500 }}
              />
            </Card>
          </Space>
        </Row>
      </Spin>
      <DrawerApp
        open={open}
        onClose={onClose}
        title={"Nova Planificação"}
        body={
          <FormNewUserPlanning
            onClose={onClose}
            user={data.find((item) => item.id == editId)}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe dos Ciclos"}
        body={
          <FormUserPlanningDetails
            onClose={onCloseDetail}
            user={data.find((item) => item.id == editId)}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
    </div>
  );
};
export default Planning;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";
import {
  ChartColumnStacked,
  Check,
  CircleAlert,
  Edit,
  Plus,
  RotateCw,
  Trash2,
  UserRound,
} from "lucide-react";
import {
  getUsersPlanning,
  getUsersPlanningFinishTraining,
  getUsersPlanningToTraining,
  saveTraining,
} from "services/planning/planning-service";
import {
  notificationErroInternal,
  notificationInfo,
  notificationSucessMsg,
} from "util/api-utils";
import { translationsTypes } from "../planning/columns-table";
import { FONT } from "constants/index";
import DrawerApp from "components/drawer-app";
import PlanningExercises from "../planning/planning-drawer-exercises";
import FormNewUserPlanningExercise from "../planning/planning-drawer-new-exercise";
import ManagerialChart from "./managerial-drawer-chart";
import { ListEmptyState } from "views/util/emptyState";
import moment from "moment";
import FormNewUserReview from "../reviews/reviews-drawer-new-review";
import FormNewUserPlanning from "../planning/planning-drawer-new-planning";

const { Meta } = Card;
const { confirm } = Modal;

const Managerial = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [userTrainer, setUserTrainer] = useState(null);
  const [openDetailItem, setOpenDetailItem] = useState(false);
  const [exercise, setExercise] = useState(null);
  const [formOpenUser] = Form.useForm();
  const [dataPlanning, setDataPlanning] = useState([]);
  const [trainingFinish, setTrainingFinish] = useState([]);
  const [openTrainingFinish, setOpenTrainingFinish] = useState(false);
  const [openChartFinish, setOpenChartFinish] = useState(false);
  const [openReview, setOpenReview] = useState({
    open: false,
    edit: false,
  });
  const [openPlaning, setOpenPlaning] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLastUpdated(Date.now());
    setLoading(true);
    getData();
    getDataPlanning();
    getDataFinishTraning();
  }
  const onCloseDetail = () => {
    setOpenDetail(false);
  };

  const onClosePlaning = () => {
    getDataFinishTraning();
    setOpenPlaning(false);
  };

  const onCloseFinishTraining = () => {
    setOpenTrainingFinish(false);
  };

  const onCloseChart = () => {
    refreshData();

    setOpenChart(false);
  };
  const onCloseChartFinish = () => {
    refreshData();

    setOpenChartFinish(false);
  };
  const onCloseReview = () => {
    getDataFinishTraning();
    setOpenReview({ open: false, edit: false });
  };
  function getDataPlanning() {
    let obj = {
      onlyHasPlan: true,
    };
    getUsersPlanning(obj)
      .then((res) => {
        setDataPlanning(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function getDataFinishTraning() {
    getUsersPlanningFinishTraining()
      .then((res) => {
        setTrainingFinish(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  function getData() {
    getUsersPlanningToTraining()
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const DescriptionItem = ({ title }) => (
    <div
      className="site-description-item-profile-wrapper"
      style={{ fontFamily: FONT, fontSize: 16 }}
    >
      <b>{title}</b>
    </div>
  );

  function hasBackSquad(exercises) {
    return (
      exercises.filter((item) => item.planningType == "BACK_SQUAD").length > 0
    );
  }

  function getTranslationType(exercise) {
    var name =
      exercise.planningType == "ADDITIONAL_EXERCISE"
        ? exercise.additionalName
        : exercise.planningType == "NEW_EXERCISE"
        ? exercise.nameCvNew
        : translationsTypes[exercise.planningType];
    return name;
  }
  const Exercises = ({ exercises }) => {
    const last = exercises[0]?.allTrainings.slice(-1);

    const load = exercises[0]?.trainings[0]?.load ?? last[0]?.load ?? 1;

    return (
      <div
        style={{
          maxHeight: 120,
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        {exercises.map((exercise, index) => (
          <Col style={{ minWidth: 450 }}>
            <Row key={index}>
              {" "}
              <Col span={8}>
                <DescriptionItem title={getTranslationType(exercise)} />
              </Col>
              <Col span={3}>
                <DescriptionItem title={exercise[`load${load}`] + "kg"} />
              </Col>
              <Col span={5}>
                <DescriptionItem
                  title={
                    exercise[`vmp${load}`] != null
                      ? exercise[`vmp${load}`] + " - VMP"
                      : ""
                  }
                />
              </Col>
              <Col span={8}>
                <DescriptionItem
                  title={
                    exercise[`rm${load}`] != null
                      ? exercise.planningType == "BARBELL_JUMP"
                        ? exercise[`rm${load}`] + " - ALT(CM)"
                        : exercise[`rm${load}`] + " - %1RM"
                      : ""
                  }
                />
              </Col>
            </Row>
          </Col>
        ))}
      </div>
    );
  };
  function getWeek(planningExercise, name) {
    const week = planningExercise[0]?.trainings[0]?.load;
    const type = planningExercise[0]?.trainings[0]?.orderTraining;

    if (week) {
      const typeTraining = type % 2 === 0 ? "B" : "A";
      const color = type % 2 === 0 ? "geekblue" : "green";

      return (
        <div>
          {name} - Semana {week} -{" "}
          <Tag color={color} key={typeTraining}>
            {typeTraining.toUpperCase()}
          </Tag>
        </div>
      );
    }
    return name + " - Cíclo Finalizado";
  }
  const CardTrainings = ({ usersTrainings }) => {
    return usersTrainings.map((user) => {
      const [formUser] = Form.useForm();
      const planExercise = user.planningTrainings.filter(
        (p) => p.planningType == "BACK_SQUAD"
      );
      let isOpen = false;
      if (planExercise.length > 0) {
        const exercise = planExercise[0].trainings;
        if (exercise.length > 0) {
          isOpen = exercise[0].forceOpen;
          formUser.setFieldValue("vmp1", exercise[0].vmp1);
          formUser.setFieldValue("vmp2", exercise[0].vmp2);
          formUser.setFieldValue("vmp3", exercise[0].vmp3);
          formUser.setFieldValue("vmp4", exercise[0].vmp4);
        }
      }

      return (
        <Col key={user.id} span={outerWidth < 800 ? 24 : 12}>
          <Card
            style={{
              width: "100%",
              marginBottom: 16,
              minHeight: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            actions={getActionCard(user, formUser)}
          >
            <Meta
              avatar={
                <Avatar
                  src={user?.img}
                  icon={<UserRound size={80} />}
                  size={80}
                />
              }
              title={<div>{getWeek(user.planningTrainings, user.name)}</div>}
              description={
                <div>
                  <Row>
                    <Col span={9}>
                      <DescriptionItem title={"Exercícios"} />
                    </Col>
                  </Row>
                  <Exercises exercises={user.planningTrainings} />

                  {hasBackSquad(user.planningTrainings) && (
                    <>
                      <Row style={{ marginTop: 10 }}>
                        <Col span={24}>
                          <DescriptionItem title={"VMP Agachamento"} />
                        </Col>
                      </Row>
                      <Form form={formUser} layout="vertical">
                        <Row gutter={16}>
                          <Col span={6}>
                            <Form.Item name="vmp1">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp2">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp3">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp4">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() =>
                                  setVmp(formUser, user.planningId, isOpen)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </div>
              }
            />
          </Card>
        </Col>
      );
    });
  };
  const CardTrainingsFinish = ({ usersTrainings }) => {
    if (usersTrainings.length == 0) {
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <ListEmptyState
            subTitle={"Não encontramos alunos com cíclo finalizado"}
          />
        </div>
      );
    }
    return usersTrainings.map((user) => {
      const planExercise = user.planningTrainings;
      let lastTraining = "";
      if (planExercise.length > 0) {
        const exercise = planExercise[0].allTrainings.slice(-1);
        if (exercise.length > 0) {
          lastTraining = moment(exercise[0].updatedAt);
        }
      }
      let isNewPlan = false;
      if (
        lastTraining != "" &&
        lastTraining.isBefore(moment(user.lastReview.reviewDate))
      ) {
        isNewPlan = true;
      }
      lastTraining = lastTraining?.format("DD/MM/YYYY");
      return (
        <>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Space>
                {isNewPlan && (
                  <Button
                    style={{
                      fontFamily: FONT,
                      background: "#1677ff",
                    }}
                    type="primary"
                    onClick={() => {
                      setUserTrainer(user);
                      setOpenPlaning(true);
                    }}
                    icon={
                      <Plus
                        size={18}
                        style={{ marginBottom: "-3px", marginRight: "7px" }}
                      />
                    }
                  >
                    Nova Planificação
                  </Button>
                )}
                <Button
                  style={{
                    fontFamily: FONT,
                    background: "#1677ff",
                  }}
                  type="primary"
                  onClick={() => {
                    setUserTrainer(user);

                    setOpenReview({
                      open: true,
                      edit: isNewPlan ? true : false,
                    });
                  }}
                  icon={
                    <Plus
                      size={18}
                      style={{ marginBottom: "-3px", marginRight: "7px" }}
                    />
                  }
                >
                  {isNewPlan ? "Editar Avaliação" : "Nova Avaliação"}
                </Button>
              </Space>
            </Col>
          </Row>
          <Col key={user.id} span={24}>
            <Card
              style={{
                width: "100%",
                marginBottom: 16,
                minHeight: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              actions={getActionCardFinish(user)}
            >
              <Meta
                avatar={
                  <Avatar
                    src={user?.img}
                    icon={<UserRound size={80} />}
                    size={80}
                  />
                }
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {getWeek(user.planningTrainings, user.name)}
                  </div>
                }
                description={
                  <div>
                    <Row>
                      <Col span={24}>
                        <div style={{ display: "flex", fontFamily: FONT }}>
                          <DescriptionItem title={"Última Avaliação"} /> {": "}
                          {moment(user.lastReview.reviewDate).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div style={{ display: "flex", fontFamily: FONT }}>
                          <DescriptionItem title={"Último Treino"} /> {": "}
                          {lastTraining}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={9}>
                        <DescriptionItem title={"Exercícios"} />
                      </Col>
                    </Row>
                    <Exercises exercises={user.planningTrainings} />
                  </div>
                }
              />
            </Card>
          </Col>
        </>
      );
    });
  };
  const formatTime = (date) => {
    if (!date) return "Nunca atualizado";
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  };
  const showDrawerDetail = () => {
    setOpenDetailItem(true);
  };
  function detail(row) {
    setExercise(row);
    showDrawerDetail();
  }
  const onCloseDetailItem = () => {
    setOpenDetailItem(false);
    setExercise(null);
  };
  const showDrawerTrainingFinish = () => {
    setOpenTrainingFinish(true);
  };

  function saveTrainingUser(user, formUser) {
    if (loading) {
      return;
    }
    if (user.planningTrainings[0].trainings.length == 0) {
      notificationInfo("Aluno com cíclo finalizado");
      return;
    }
    setLoading(true);

    const obj = {
      isDone: true,
      planningId: [user.planningId],
      vmp1: formUser.getFieldValue("vmp1"),
      vmp2: formUser.getFieldValue("vmp2"),
      vmp3: formUser.getFieldValue("vmp3"),
      vmp4: formUser.getFieldValue("vmp4"),
    };
    saveTraining(obj)
      .then(() => {
        notificationSucessMsg("Treino finalizado com sucesso");
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  function getActionCard(user, form) {
    if (hasBackSquad(user.planningTrainings)) {
      return [
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenChart(true);
          }}
        >
          <ChartColumnStacked />
        </div>,

        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
        <div onClick={() => setVmp(form, user.planningId, false, true)}>
          <Trash2 />
        </div>,
        <div onClick={() => saveTrainingUser(user, form)}>
          <Check />
        </div>,
      ];
    } else {
      return [
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
        <div onClick={() => setVmp(form, user.planningId, false, true)}>
          <Trash2 />
        </div>,
        <div onClick={() => saveTrainingUser(user, form)}>
          <Check />
        </div>,
      ];
    }
  }
  function getActionCardFinish(user) {
    if (hasBackSquad(user.planningTrainings)) {
      return [
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenChartFinish(true);
          }}
        >
          <ChartColumnStacked />
        </div>,

        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
      ];
    } else {
      return [];
    }
  }
  function setVmp(formUser, planningId, isOpen, forceClose) {
    setLoading(true);
    const obj = {
      isDone: false,
      planningId: [planningId],
      vmp1: formUser.getFieldValue("vmp1"),
      vmp2: formUser.getFieldValue("vmp2"),
      vmp3: formUser.getFieldValue("vmp3"),
      vmp4: formUser.getFieldValue("vmp4"),
      isOpen: isOpen,
      forceClose: forceClose,
    };
    saveTraining(obj)
      .then(() => {
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  function updateList() {
    const idsToRemove = new Set(data.map((item) => item.id));
    const idsFinishTraining = new Set(trainingFinish.map((item) => item.id));

    const result = dataPlanning.filter(
      (item) => !idsToRemove.has(item.id) && !idsFinishTraining.has(item.id)
    );
    if (result.length == 0) {
      notificationInfo(
        "Todos os alunos disponíveis estão no painel ou com cíclos finalizados"
      );
      return;
    }

    openTraing(result);
  }

  function openTraing(result) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Qual Aluno ?
          </span>
          <div style={{ marginTop: "10px" }}>
            <Form form={formOpenUser} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="userOpen">
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                    >
                      {result.map((item) => {
                        return (
                          <Option value={item.lastPlanId}>{item.name}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="typeOpen">
                    <Radio.Group
                      options={[
                        { label: "Último Treino", value: "LAST" },
                        { label: "Novo Treino", value: "NEW" },
                      ]}
                      defaultValue={"NEW"}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ),
      icon: null,
      okText: "Selecionar",
      cancelText: "Cancelar",
      onOk() {
        setLoading(true);

        const planId = formOpenUser.getFieldValue("userOpen");
        const typeOpen = formOpenUser.getFieldValue("typeOpen");
        if (planId?.length == 0 || !planId) {
          notificationInfo("É nescessário informar o aluno");
          setLoading(false);
          return;
        }
        const obj = {
          isDone: false,
          isOpen: true,
          planningId: planId,
          typeOpen: typeOpen ?? "NEW",
        };
        saveTraining(obj)
          .then(() => {
            formOpenUser.resetFields();
            refreshData();
          })
          .catch((err) => {
            formOpenUser.resetFields();
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {
        formOpenUser.resetFields();
      },
    });
  }
  return (
    <div className="container-managerial">
      <Row gutter={24} style={{ padding: "10px" }}>
        <Space>
          <Button
            style={{
              fontFamily: FONT,
              background: "#09ac9c",
            }}
            type="primary"
            icon={
              <RotateCw
                size={18}
                style={{ marginBottom: "-3px", marginRight: "7px" }}
              />
            }
            onClick={refreshData}
          >
            Atualizar
          </Button>
          <Button
            style={{
              fontFamily: FONT,
            }}
            type="primary"
            icon={
              <Plus
                size={18}
                style={{ marginBottom: "-3px", marginRight: "7px" }}
              />
            }
            onClick={updateList}
          >
            Abrir Treino
          </Button>
          <Badge count={trainingFinish.length} overflowCount={10}>
            <Button
              style={{
                fontFamily: FONT,
                background: "#fcad03",
              }}
              type="primary"
              onClick={showDrawerTrainingFinish}
            >
              Finalizados
            </Button>
          </Badge>
        </Space>
      </Row>
      <Row>
        <div style={{ fontFamily: FONT, fontSize: 12, marginBottom: 15 }}>
          Última atualização: {formatTime(lastUpdated)}{" "}
        </div>
      </Row>
      <Spin spinning={loading}>
        <Row gutter={16}>
          {data.length > 0 ? (
            <CardTrainings usersTrainings={data} />
          ) : (
            <div style={{ textAlign: "center", width: "100%" }}>
              <ListEmptyState subTitle={"A sessão de treino está vazia"} />
            </div>
          )}
        </Row>
      </Spin>

      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe do Ciclo"}
        body={""}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe do Ciclo"}
        body={
          <PlanningExercises
            isOnlyView={true}
            exercises={
              userTrainer != null
                ? userTrainer.planningTrainings.map((item) => {
                    return {
                      ...item,
                      type: item.planningType,
                      cycle: item.cycleName?.replace(/\D/g, ""),
                      detail: detail,
                    };
                  })
                : []
            }
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />

      <DrawerApp
        open={openDetailItem}
        onClose={onCloseDetailItem}
        title={"Detalhe do Exercicio"}
        body={
          <FormNewUserPlanningExercise
            onClose={onCloseDetailItem}
            user={userTrainer}
            exercise={exercise}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openTrainingFinish}
        onClose={onCloseFinishTraining}
        title={"Cíclos Finalizados"}
        body={<CardTrainingsFinish usersTrainings={trainingFinish} />}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "45%"}
      />

      <DrawerApp
        open={openChart}
        onClose={onCloseChart}
        title={"Gráfico de Evolução"}
        body={<ManagerialChart onClose={onCloseChart} user={userTrainer} />}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openChartFinish}
        onClose={onCloseChartFinish}
        title={"Gráfico de Evolução"}
        body={
          <ManagerialChart onClose={onCloseChartFinish} user={userTrainer} />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openReview.open}
        onClose={onCloseReview}
        title={"Nova Avaliação"}
        body={
          openReview.edit ? (
            <FormNewUserReview
              user={userTrainer}
              showSprints={false}
              editId={userTrainer.lastReview?.id}
              editRow={userTrainer.lastReview}
              onClose={onCloseReview}
            />
          ) : (
            <FormNewUserReview
              user={userTrainer}
              showSprints={true}
              onClose={onCloseReview}
            />
          )
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />

      <DrawerApp
        open={openPlaning}
        onClose={onClosePlaning}
        title={"Nova Planificação"}
        body={
          <FormNewUserPlanning onClose={onClosePlaning} user={userTrainer} />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
    </div>
  );
};

export default Managerial;

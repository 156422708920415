import React from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const chartBar = (
  data,
  dataKeyX,
  dataKeyActual,
  dataKeyComparation,
  dataNameActual,
  dataNameComparation,
  tickCount,
  content
) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis tickCount={tickCount} />
        {content ? <Tooltip content={content} /> : <Tooltip />}

        <Bar dataKey={dataKeyActual} name={dataNameActual} fill="#14B8A6" />
        <Bar
          dataKey={dataKeyComparation}
          name={dataNameComparation}
          fill="#3B82F6"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const chartLiner = (
  data,
  dataKeyX,
  dataKeyActual,
  dataKeyComparation,
  dataNameActual,
  dataNameComparation,
  tickCount
) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} />
        <YAxis tickCount={tickCount} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey={dataKeyActual}
          name={dataNameActual}
          stroke="#14B8A6"
        />
        <Line
          type="monotone"
          dataKey={dataKeyComparation}
          name={dataNameComparation}
          stroke="#3B82F6"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

import RouterApp from "./router-config";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");
import ptBR from "antd/lib/locale/pt_BR";
import { ConfigProvider } from "antd";

ReactDOM.render(
  <div>
    <ConfigProvider locale={ptBR}>
      <Provider store={store}>
        <RouterApp />
      </Provider>
    </ConfigProvider>
  </div>,
  document.getElementById("root")
);

import Services from "api/ProjectApiConsumer";

export function getUsersPlanning(obj) {
  return Services.planningService.getUsersPlanning(undefined, obj);
}

export function getUsersPlanningToTraining() {
  return Services.planningService.getUsersPlanningToTraining();
}
export function getUsersPlanningFinishTraining() {
  return Services.planningService.getUsersPlanningFinishTraining();
}


export function savePlanning(obj) {
  return Services.planningService.savePlanning(obj);
}

export function getPlanningUserById(id) {
  return Services.planningService.getPlanningUserById(undefined, undefined, id);
}

export function deletePlanning(id) {
  return Services.planningService.deletePlanning(undefined, undefined, id);
}

export function saveTraining(obj) {
  return Services.planningService.saveTraining(obj);
}
export function updateTraining (obj) {
  return Services.planningService.updateTraining(obj);
}

